import { template as template_0518f04f812c434cabf626be9bdf75c3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0518f04f812c434cabf626be9bdf75c3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
