import { template as template_71c617f7b0e24de8a713fff4d8c44ba0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_71c617f7b0e24de8a713fff4d8c44ba0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
