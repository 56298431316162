import { template as template_00c0ad575c124bc69a9297dc230535bf } from "@ember/template-compiler";
const FKText = template_00c0ad575c124bc69a9297dc230535bf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
