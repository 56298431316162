import { template as template_6c7991e3d2a6460cb5d742c5ec83f415 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_6c7991e3d2a6460cb5d742c5ec83f415(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
